import {FrameworkConfiguration, PLATFORM} from 'aurelia-framework';

export function configure(config: FrameworkConfiguration) {
  config.globalResources([PLATFORM.moduleName("./pager/pager")]);
  config.globalResources([PLATFORM.moduleName("./reporting-frequency-selector/reporting-frequency-selector")]);
  config.globalResources([PLATFORM.moduleName("./reporting-term-selector/reporting-term-selector")]);
  config.globalResources([PLATFORM.moduleName("./organization-selector/organization-selector")]);
  config.globalResources([PLATFORM.moduleName("./organization-type-selector/organization-type-selector")]);
  config.globalResources([PLATFORM.moduleName("./organization-role-type-selector/organization-role-type-selector")]);
  config.globalResources([PLATFORM.moduleName("./report-status-selector/report-status-selector")]);
  config.globalResources([PLATFORM.moduleName("./money-selector/money-selector")]);
  config.globalResources([PLATFORM.moduleName("./input-number/input-number")]);
  config.globalResources([PLATFORM.moduleName("./library-this-period/library-this-period")]);
  config.globalResources([PLATFORM.moduleName("./value-converters/NumberValueConverter")]);
  config.globalResources([PLATFORM.moduleName("./value-converters/OrganizationTypeValueConverter")]);
  config.globalResources([PLATFORM.moduleName("./value-converters/ReportingFrequencyValueConverter")]);
  config.globalResources([PLATFORM.moduleName("./value-converters/ReportingPeriodValueConverter")]);
  config.globalResources([PLATFORM.moduleName("./value-converters/ConvertToLocalDateValueConverter")]);
  config.globalResources([PLATFORM.moduleName("./value-converters/ConvertToLocalDateOnlyValueConverter")]);
  config.globalResources([PLATFORM.moduleName("./value-converters/ReportingTermValueConverter")]);
  config.globalResources([PLATFORM.moduleName("./value-converters/ReportPlanStatusValueConverter")]);
  config.globalResources([PLATFORM.moduleName("./value-converters/ReportStatusValueConverter")]);
  config.globalResources([PLATFORM.moduleName("./value-converters/CanEditPlanValueConverter")]);
  config.globalResources([PLATFORM.moduleName("./value-converters/CanViewReportValueConverter")]);
  config.globalResources([PLATFORM.moduleName("./value-converters/CanEditReportValueConverter")]);
  config.globalResources([PLATFORM.moduleName("./value-converters/MoneyValueConverter")]);
  config.globalResources([PLATFORM.moduleName("./value-converters/ReportingPeriodViewModelValueConverter")]);
  config.globalResources([PLATFORM.moduleName("./numeric-only-attribute/numeric-only-attribute")]);
  config.globalResources([PLATFORM.moduleName("./decimal-only-attribute/decimal-only-attribute")]);
  config.globalResources([PLATFORM.moduleName("./member-data-this-period/member-data-this-period")]);
  config.globalResources([PLATFORM.moduleName("./plan-report-header/plan-report-header")]);
  config.globalResources([PLATFORM.moduleName("./plan-report-shell/plan-report-shell")]);
  
  
  // Plan
  // config.globalResources([PLATFORM.moduleName("./plan-manpower-view/plan-manpower-view")]);
  config.globalResources([PLATFORM.moduleName("./unit-plan-manpower-view/unit-plan-manpower-view")]);
  config.globalResources([PLATFORM.moduleName("./state-plan-manpower-view/state-plan-manpower-view")]);
  config.globalResources([PLATFORM.moduleName("./zone-plan-manpower-view/zone-plan-manpower-view")]);
  config.globalResources([PLATFORM.moduleName("./central-plan-manpower-view/central-plan-manpower-view")]);
  
  // config.globalResources([PLATFORM.moduleName("./plan-manpower-edit/plan-manpower-edit")]);
  config.globalResources([PLATFORM.moduleName("./unit-plan-manpower-edit/unit-plan-manpower-edit")]);
  config.globalResources([PLATFORM.moduleName("./state-plan-manpower-edit/state-plan-manpower-edit")]);
  config.globalResources([PLATFORM.moduleName("./zone-plan-manpower-edit/zone-plan-manpower-edit")]);
  config.globalResources([PLATFORM.moduleName("./central-plan-manpower-edit/central-plan-manpower-edit")]);
  
  // config.globalResources([PLATFORM.moduleName("./plan-regular-meetings-view/plan-regular-meetings-view")]);
  config.globalResources([PLATFORM.moduleName("./unit-plan-regular-meetings-view/unit-plan-regular-meetings-view")]);
  config.globalResources([PLATFORM.moduleName("./state-plan-regular-meetings-view/state-plan-regular-meetings-view")]);
  config.globalResources([PLATFORM.moduleName("./zone-plan-regular-meetings-view/zone-plan-regular-meetings-view")]);
  config.globalResources([PLATFORM.moduleName("./central-plan-regular-meetings-view/central-plan-regular-meetings-view")]);
  
  // config.globalResources([PLATFORM.moduleName("./plan-regular-meetings-edit/plan-regular-meetings-edit")]);
  config.globalResources([PLATFORM.moduleName("./unit-plan-regular-meetings-edit/unit-plan-regular-meetings-edit")]);
  config.globalResources([PLATFORM.moduleName("./state-plan-regular-meetings-edit/state-plan-regular-meetings-edit")]);
  config.globalResources([PLATFORM.moduleName("./zone-plan-regular-meetings-edit/zone-plan-regular-meetings-edit")]);
  config.globalResources([PLATFORM.moduleName("./central-plan-regular-meetings-edit/central-plan-regular-meetings-edit")]);
  
  // config.globalResources([PLATFORM.moduleName("./plan-teaching-view/plan-teaching-view")]);
  config.globalResources([PLATFORM.moduleName("./unit-plan-teaching-view/unit-plan-teaching-view")]);
  config.globalResources([PLATFORM.moduleName("./state-plan-teaching-view/state-plan-teaching-view")]);
  config.globalResources([PLATFORM.moduleName("./zone-plan-teaching-view/zone-plan-teaching-view")]);
  config.globalResources([PLATFORM.moduleName("./central-plan-teaching-view/central-plan-teaching-view")]);
  
  // config.globalResources([PLATFORM.moduleName("./plan-teaching-edit/plan-teaching-edit")]);
  config.globalResources([PLATFORM.moduleName("./unit-plan-teaching-edit/unit-plan-teaching-edit")]);
  config.globalResources([PLATFORM.moduleName("./state-plan-teaching-edit/state-plan-teaching-edit")]);
  config.globalResources([PLATFORM.moduleName("./zone-plan-teaching-edit/zone-plan-teaching-edit")]);
  config.globalResources([PLATFORM.moduleName("./central-plan-teaching-edit/central-plan-teaching-edit")]);
  
  // config.globalResources([PLATFORM.moduleName("./plan-socialwelfare-view/plan-socialwelfare-view")]);
  config.globalResources([PLATFORM.moduleName("./unit-plan-socialwelfare-view/unit-plan-socialwelfare-view")]);
  config.globalResources([PLATFORM.moduleName("./state-plan-socialwelfare-view/state-plan-socialwelfare-view")]);
  config.globalResources([PLATFORM.moduleName("./zone-plan-socialwelfare-view/zone-plan-socialwelfare-view")]);
  config.globalResources([PLATFORM.moduleName("./central-plan-socialwelfare-view/central-plan-socialwelfare-view")]);
  
  // config.globalResources([PLATFORM.moduleName("./plan-socialwelfare-edit/plan-socialwelfare-edit")]);
  config.globalResources([PLATFORM.moduleName("./unit-plan-socialwelfare-edit/unit-plan-socialwelfare-edit")]);
  config.globalResources([PLATFORM.moduleName("./state-plan-socialwelfare-edit/state-plan-socialwelfare-edit")]);
  config.globalResources([PLATFORM.moduleName("./zone-plan-socialwelfare-edit/zone-plan-socialwelfare-edit")]);
  config.globalResources([PLATFORM.moduleName("./central-plan-socialwelfare-edit/central-plan-socialwelfare-edit")]);
  
  // config.globalResources([PLATFORM.moduleName("./plan-material-view/plan-material-view")]);
  config.globalResources([PLATFORM.moduleName("./unit-plan-material-view/unit-plan-material-view")]);
  config.globalResources([PLATFORM.moduleName("./state-plan-material-view/state-plan-material-view")]);
  config.globalResources([PLATFORM.moduleName("./zone-plan-material-view/zone-plan-material-view")]);
  config.globalResources([PLATFORM.moduleName("./central-plan-material-view/central-plan-material-view")]);
  
  // config.globalResources([PLATFORM.moduleName("./plan-material-edit/plan-material-edit")]);
  config.globalResources([PLATFORM.moduleName("./unit-plan-material-edit/unit-plan-material-edit")]);
  config.globalResources([PLATFORM.moduleName("./state-plan-material-edit/state-plan-material-edit")]);
  config.globalResources([PLATFORM.moduleName("./zone-plan-material-edit/zone-plan-material-edit")]);
  config.globalResources([PLATFORM.moduleName("./central-plan-material-edit/central-plan-material-edit")]);
  
  // config.globalResources([PLATFORM.moduleName("./plan-finance-view/plan-finance-view")]);
  config.globalResources([PLATFORM.moduleName("./unit-plan-finance-view/unit-plan-finance-view")]);
  config.globalResources([PLATFORM.moduleName("./state-plan-finance-view/state-plan-finance-view")]);
  config.globalResources([PLATFORM.moduleName("./zone-plan-finance-view/zone-plan-finance-view")]);
  config.globalResources([PLATFORM.moduleName("./central-plan-finance-view/central-plan-finance-view")]);
  
  // config.globalResources([PLATFORM.moduleName("./plan-finance-edit/plan-finance-edit")]);
  config.globalResources([PLATFORM.moduleName("./unit-plan-finance-edit/unit-plan-finance-edit")]);
  config.globalResources([PLATFORM.moduleName("./state-plan-finance-edit/state-plan-finance-edit")]);
  config.globalResources([PLATFORM.moduleName("./zone-plan-finance-edit/zone-plan-finance-edit")]);
  config.globalResources([PLATFORM.moduleName("./central-plan-finance-edit/central-plan-finance-edit")]);
  
  
  // Report
  // config.globalResources([PLATFORM.moduleName("./report-manpower-view/report-manpower-view")]);
  config.globalResources([PLATFORM.moduleName("./unit-report-manpower-view/unit-report-manpower-view")]);
  config.globalResources([PLATFORM.moduleName("./state-report-manpower-view/state-report-manpower-view")]);
  config.globalResources([PLATFORM.moduleName("./zone-report-manpower-view/zone-report-manpower-view")]);
  config.globalResources([PLATFORM.moduleName("./central-report-manpower-view/central-report-manpower-view")]);
  
  // config.globalResources([PLATFORM.moduleName("./report-manpower-edit/report-manpower-edit")]);
  config.globalResources([PLATFORM.moduleName("./unit-report-manpower-edit/unit-report-manpower-edit")]);
  config.globalResources([PLATFORM.moduleName("./state-report-manpower-edit/state-report-manpower-edit")]);
  config.globalResources([PLATFORM.moduleName("./zone-report-manpower-edit/zone-report-manpower-edit")]);
  config.globalResources([PLATFORM.moduleName("./central-report-manpower-edit/central-report-manpower-edit")]);
  
  // config.globalResources([PLATFORM.moduleName("./report-regular-meetings-view/report-regular-meetings-view")]);
  config.globalResources([PLATFORM.moduleName("./unit-report-regular-meetings-view/unit-report-regular-meetings-view")]);
  config.globalResources([PLATFORM.moduleName("./state-report-regular-meetings-view/state-report-regular-meetings-view")]);
  config.globalResources([PLATFORM.moduleName("./zone-report-regular-meetings-view/zone-report-regular-meetings-view")]);
  config.globalResources([PLATFORM.moduleName("./central-report-regular-meetings-view/central-report-regular-meetings-view")]);
  
  // config.globalResources([PLATFORM.moduleName("./report-regular-meetings-edit/report-regular-meetings-edit")]);
  config.globalResources([PLATFORM.moduleName("./unit-report-regular-meetings-edit/unit-report-regular-meetings-edit")]);
  config.globalResources([PLATFORM.moduleName("./state-report-regular-meetings-edit/state-report-regular-meetings-edit")]);
  config.globalResources([PLATFORM.moduleName("./zone-report-regular-meetings-edit/zone-report-regular-meetings-edit")]);
  config.globalResources([PLATFORM.moduleName("./central-report-regular-meetings-edit/central-report-regular-meetings-edit")]);
  
  // config.globalResources([PLATFORM.moduleName("./report-teaching-view/report-teaching-view")]);
  config.globalResources([PLATFORM.moduleName("./unit-report-teaching-view/unit-report-teaching-view")]);
  config.globalResources([PLATFORM.moduleName("./state-report-teaching-view/state-report-teaching-view")]);
  config.globalResources([PLATFORM.moduleName("./zone-report-teaching-view/zone-report-teaching-view")]);
  config.globalResources([PLATFORM.moduleName("./central-report-teaching-view/central-report-teaching-view")]);
  
  // config.globalResources([PLATFORM.moduleName("./report-teaching-edit/report-teaching-edit")]);
  config.globalResources([PLATFORM.moduleName("./unit-report-teaching-edit/unit-report-teaching-edit")]);
  config.globalResources([PLATFORM.moduleName("./state-report-teaching-edit/state-report-teaching-edit")]);
  config.globalResources([PLATFORM.moduleName("./zone-report-teaching-edit/zone-report-teaching-edit")]);
  config.globalResources([PLATFORM.moduleName("./central-report-teaching-edit/central-report-teaching-edit")]);
  
  // config.globalResources([PLATFORM.moduleName("./report-socialwelfare-view/report-socialwelfare-view")]);
  config.globalResources([PLATFORM.moduleName("./unit-report-socialwelfare-view/unit-report-socialwelfare-view")]);
  config.globalResources([PLATFORM.moduleName("./state-report-socialwelfare-view/state-report-socialwelfare-view")]);
  config.globalResources([PLATFORM.moduleName("./zone-report-socialwelfare-view/zone-report-socialwelfare-view")]);
  config.globalResources([PLATFORM.moduleName("./central-report-socialwelfare-view/central-report-socialwelfare-view")]);
  
  // config.globalResources([PLATFORM.moduleName("./report-socialwelfare-edit/report-socialwelfare-edit")]);
  config.globalResources([PLATFORM.moduleName("./unit-report-socialwelfare-edit/unit-report-socialwelfare-edit")]);
  config.globalResources([PLATFORM.moduleName("./state-report-socialwelfare-edit/state-report-socialwelfare-edit")]);
  config.globalResources([PLATFORM.moduleName("./zone-report-socialwelfare-edit/zone-report-socialwelfare-edit")]);
  config.globalResources([PLATFORM.moduleName("./central-report-socialwelfare-edit/central-report-socialwelfare-edit")]);
  
  // config.globalResources([PLATFORM.moduleName("./report-material-view/report-material-view")]);
  config.globalResources([PLATFORM.moduleName("./unit-report-material-view/unit-report-material-view")]);
  config.globalResources([PLATFORM.moduleName("./state-report-material-view/state-report-material-view")]);
  config.globalResources([PLATFORM.moduleName("./zone-report-material-view/zone-report-material-view")]);
  config.globalResources([PLATFORM.moduleName("./central-report-material-view/central-report-material-view")]);
  
  // config.globalResources([PLATFORM.moduleName("./report-material-edit/report-material-edit")]);
  config.globalResources([PLATFORM.moduleName("./unit-report-material-edit/unit-report-material-edit")]);
  config.globalResources([PLATFORM.moduleName("./state-report-material-edit/state-report-material-edit")]);
  config.globalResources([PLATFORM.moduleName("./zone-report-material-edit/zone-report-material-edit")]);
  config.globalResources([PLATFORM.moduleName("./central-report-material-edit/central-report-material-edit")]);
  
  // config.globalResources([PLATFORM.moduleName("./report-library-view/report-library-view")]);
  config.globalResources([PLATFORM.moduleName("./unit-report-library-view/unit-report-library-view")]);
  config.globalResources([PLATFORM.moduleName("./state-report-library-view/state-report-library-view")]);
  config.globalResources([PLATFORM.moduleName("./zone-report-library-view/zone-report-library-view")]);
  config.globalResources([PLATFORM.moduleName("./central-report-library-view/central-report-library-view")]);
  
  // config.globalResources([PLATFORM.moduleName("./report-library-edit/report-library-edit")]);
  config.globalResources([PLATFORM.moduleName("./unit-report-library-edit/unit-report-library-edit")]);
  config.globalResources([PLATFORM.moduleName("./state-report-library-edit/state-report-library-edit")]);
  config.globalResources([PLATFORM.moduleName("./zone-report-library-edit/zone-report-library-edit")]);
  config.globalResources([PLATFORM.moduleName("./central-report-library-edit/central-report-library-edit")]);
  
  // config.globalResources([PLATFORM.moduleName("./report-finance-view/report-finance-view")]);
  config.globalResources([PLATFORM.moduleName("./unit-report-finance-view/unit-report-finance-view")]);
  config.globalResources([PLATFORM.moduleName("./state-report-finance-view/state-report-finance-view")]);
  config.globalResources([PLATFORM.moduleName("./zone-report-finance-view/zone-report-finance-view")]);
  config.globalResources([PLATFORM.moduleName("./central-report-finance-view/central-report-finance-view")]);
  
  // config.globalResources([PLATFORM.moduleName("./report-finance-edit/report-finance-edit")]);
  config.globalResources([PLATFORM.moduleName("./unit-report-finance-edit/unit-report-finance-edit")]);
  config.globalResources([PLATFORM.moduleName("./state-report-finance-edit/state-report-finance-edit")]);
  config.globalResources([PLATFORM.moduleName("./zone-report-finance-edit/zone-report-finance-edit")]);
  config.globalResources([PLATFORM.moduleName("./central-report-finance-edit/central-report-finance-edit")]);
  
  
  config.globalResources([PLATFORM.moduleName("./finance-balance/finance-balance")]);
  config.globalResources([PLATFORM.moduleName("./unit-finance-expense/unit-finance-expense")]);
  config.globalResources([PLATFORM.moduleName("./unit-finance-nisab/unit-finance-nisab")]);
  config.globalResources([PLATFORM.moduleName("./finance-worker-promise-this-period/finance-worker-promise-this-period")]);
  config.globalResources([PLATFORM.moduleName("./reporting-period-selector/reporting-period-selector")]);
  config.globalResources([
    PLATFORM.moduleName("./report-manpower-lastperiod-edit/report-manpower-lastperiod-edit"),
    PLATFORM.moduleName("./report-finance-lastperiod-edit/report-finance-lastperiod-edit"),
    PLATFORM.moduleName("./report-library-lastperiod-edit/report-library-lastperiod-edit")
  ]);
}
